import React, { SVGProps } from 'react'

const FlagCzechRepublic = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 15"
    fill="none"
    aria-hidden="true"
    {...props}
  >
    <path
      d="M.768 13.874c.394.369.914.573 1.454.57h15.556c1.227 0 2.222-.995 2.222-2.5V7.222H9.722L.768 13.874z"
      fill="#D7141A"
    />
    <path
      d="M17.778 0H2.222C1.68 0 1.157.199.752.559l8.97 6.663H20v-5A2.222 2.222 0 0017.778 0z"
      fill="#EEE"
    />
    <path
      d="M.768 13.874l8.954-6.652L.752.56A2.222 2.222 0 000 2.222v9.722c0 .823.298 1.491.768 1.93z"
      fill="#11457E"
    />
  </svg>
)

export default FlagCzechRepublic
