import React, { SVGProps } from 'react'

const FlagPoland = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 15"
    fill="none"
    aria-hidden="true"
    {...props}
  >
    <path
      d="M17.778 0H2.222A2.222 2.222 0 000 2.222v5h20v-5A2.222 2.222 0 0017.778 0z"
      fill="#EEE"
    />
    <path
      d="M0 12.222a2.222 2.222 0 002.222 2.222h15.556A2.222 2.222 0 0020 12.222v-5H0v5z"
      fill="#DC143C"
    />
  </svg>
)

export default FlagPoland
