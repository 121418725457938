import styled from '@emotion/styled'

import { minWidth } from '@emico/styles'

import FlagAustria from '../icons/FlagAustria'
import FlagBelgium from '../icons/FlagBelgium'
import FlagCzechRepublic from '../icons/FlagCzechRepublic'
import FlagDenmark from '../icons/FlagDenmark'
import FlagFrance from '../icons/FlagFrance'
import FlagGermany from '../icons/FlagGermany'
import FlagIreland from '../icons/FlagIreland'
import FlagItaly from '../icons/FlagItaly'
import FlagNetherlands from '../icons/FlagNetherlands'
import FlagNorway from '../icons/FlagNorway'
import FlagPoland from '../icons/FlagPoland'
import FlagSpain from '../icons/FlagSpain'
import FlagSweden from '../icons/FlagSweden'
import FlagUK from '../icons/FlagUK'
import FlagUS from '../icons/FlagUS'
import GlobeIcon from '../icons/GlobeIcon'

const StyledGlobeIcon = styled(GlobeIcon)`
  font-size: 16px;

  @media ${minWidth('lg')} {
    font-size: 20px;
  }
`

const storeSelectorCountries = [
  {
    name: 'België (NL)',
    flag: <FlagBelgium />,
    code: 'b2c_be_nl',
    base: '/be_nl',
  },
  {
    name: 'Belgique (FR)',
    flag: <FlagBelgium />,
    code: 'b2c_be_fr',
    base: '/be_fr',
  },
  {
    name: 'Česká republika',
    flag: <FlagCzechRepublic />,
    code: 'b2c_cz',
    base: '/cz',
  },
  {
    name: 'Danmark',
    flag: <FlagDenmark />,
    code: 'b2c_dk',
    base: '/dk',
  },
  {
    name: 'Deutschland',
    flag: <FlagGermany />,
    code: 'b2c_de',
    base: '/de',
  },
  {
    name: 'España',
    flag: <FlagSpain />,
    code: 'b2c_es_es',
    base: '/es',
  },
  {
    name: 'France',
    flag: <FlagFrance />,
    code: 'b2c_fr',
    base: '/fr',
  },
  {
    name: 'Ireland',
    flag: <FlagIreland />,
    code: 'b2c_ie',
    base: '/ie',
  },
  {
    name: 'Italia',
    flag: <FlagItaly />,
    code: 'b2c_it',
    base: '/it',
  },
  {
    name: 'Nederland',
    flag: <FlagNetherlands />,
    code: 'b2c_nl',
    base: '/nl',
  },
  {
    name: 'Norge',
    flag: <FlagNorway />,
    code: 'b2c_no',
    base: '/no',
  },
  {
    name: 'Österreich',
    flag: <FlagAustria />,
    code: 'b2c_at',
    base: '/at',
  },
  {
    name: 'Polska',
    flag: <FlagPoland />,
    code: 'b2c_pl',
    base: '/pl',
  },
  {
    name: 'Sverige',
    flag: <FlagSweden />,
    code: 'b2c_se',
    base: '/se',
  },
  {
    name: 'United Kingdom',
    flag: <FlagUK />,
    code: 'b2c_uk',
    base: '/uk',
  },
  {
    name: 'United States',
    flag: <FlagUS />,
    code: 'b2c_us',
    base: 'https://us.bergtoys.com/',
  },
]

export const storeSelectorGlobals = [
  {
    name: 'English',
    flag: <StyledGlobeIcon />,
    code: 'int_en',
    base: `/int_en`,
  },
  {
    name: 'Español',
    flag: <StyledGlobeIcon />,
    code: 'b2c_es',
    base: `/int_es`,
  },
]

export function getCurrentStoreCountry(storeConfigCode: string | undefined) {
  return [...storeSelectorCountries, ...storeSelectorGlobals].find(
    (country) => country.code === storeConfigCode,
  )
}

export default storeSelectorCountries
